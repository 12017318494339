import React from 'react'
import './App.css';
import { FaDiscord, FaTwitter } from "react-icons/fa";
import logo from './logo.png';


const Mintcf = () => (
  <div className="gradient__bg">
    <div className="cf__navbar " >
      <div className="cf__navbar-links">
        <a className="cf__navbar-links_logo" href="https://cypherface.xyz/">
          <img src={logo} alt="logo"/>
        </a>
      </div>
        <div className="cf__navbar-icon">
          <a href="https://discord.gg/fkVkZ5vXGS">
            <FaDiscord size = '40' ></FaDiscord>
          </a>
        </div>
        <div className="cf__navbar-icon">
          <a href="https://twitter.com/Cypher_face">
            <FaTwitter size = '40' ></FaTwitter>
          </a>        
        </div>
    </div>
      <div className="cf__mint_content">
        <iframe
        src="https://gateway.ipfscdn.io/ipfs/QmbAgC8YwY36n8H2kuvSWsRisxDZ15QZw3xGZyk9aDvcv7/erc721.html?contract=0x641Cd29442A0f5641c2C3A30D25A473DA2E343F6&chain=%7B%22name%22%3A%22Ethereum+Mainnet%22%2C%22chain%22%3A%22ETH%22%2C%22rpc%22%3A%5B%22https%3A%2F%2Fethereum.rpc.thirdweb.com%2F5a9bc94b87f7cbbbfbbc234bf1e07f0adf5f3cf3012c9f26f9fc9820d64df93a%22%5D%2C%22nativeCurrency%22%3A%7B%22name%22%3A%22Ether%22%2C%22symbol%22%3A%22ETH%22%2C%22decimals%22%3A18%7D%2C%22shortName%22%3A%22eth%22%2C%22chainId%22%3A1%2C%22testnet%22%3Afalse%2C%22slug%22%3A%22ethereum%22%7D&theme=dark"
        width="600px"
        height="600px"
        style={{ maxWidth: '100%' }}
        frameborder="0"
        title="cf__mint_iframe">
        
        </iframe>
      </div>
  </div>
);

export default Mintcf
